import React from "react";
import "./Aboutsec.css";

import abtimg from "../../Assets/images/techmont.gif";
// import cube from '../../Assets/images/cube.gif'

const Aboutsec = () => {
  return (
    <>
      <section id="aboutusSrl" className="about-section py-5 md:py-12  ">
        <div className="container mx-auto px-2 sm:px-4 relative">
          <div className="flex flex-col md:flex-row   items-center justify-between">
            <div className="w-full md:w-[1/2] order-2 md:order-1 " data-aos="fade-Up"  data-aos-duration={1500} data-aos-delay={200} >
              <div className="h2div max-w-[110px] w-full mb-2 mx-auto md:mx-0  ">
                <h3 className="text-center">About us</h3>
              </div>
              <h2 className="about-heading text-center md:text-left leading-none">
                <span className="hero-connecting">
                  {" "}
                  Introducing{" "}
                  <span className="hero-communities"> Techmont's</span>{" "}
                  Cutting-Edge Solution{" "}
                </span>
              </h2>

              <div className="abtbx mx-auto pt-4 md:mx-0 ">
                <a href="#" className="about-box   p-[20px] cursor-auto">
                  <p className=" text-justify ">
                    At Techmont, we bring together a team of experienced
                    developers and blockchain specialists dedicated to fostering
                    an inclusive and decentralized digital future. Our mission
                    is to empower individuals with complete control over their
                    digital assets and identities.
                  </p>
                  <div className="bnrbtn mb-5 sm:mb-0 flex flex-wrap justify-center md:justify-start  mt-6 gap-4 sm:gap-7">
                  <a href="https://docs.techmont.finance/" target="_blank" className="tech_btn flex items-center">
                    <i></i>
                    <i></i>
                    <span>Whitepaper</span>
                  </a>
                  <a
                    href="#"  target="_blank"
                    className="tech_btn flex items-center"
                  >
                    <i></i>
                    <i></i>
                    <span>Certified</span>
                  </a>
                  </div>

                  <div className="section_about-arrow-wrapper">
                    
                    <div className="section_about-arrow invisible w-embed">
                      <svg
                        width={21}
                        height={20}
                        viewBox="0 0 21 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M15.919 8.81575L9.06818 2.46382L10.8741 0.789396L20.8081 9.99992L10.8741 19.2104L9.06818 17.536L15.919 11.1841L0.373047 11.1841L0.373046 8.81575L15.919 8.81575Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                    <div className="section_about-arrow w-embed">
                      <svg
                        width={21}
                        height={20}
                        viewBox="0 0 21 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M15.919 8.81575L9.06818 2.46382L10.8741 0.789396L20.8081 9.99992L10.8741 19.2104L9.06818 17.536L15.919 11.1841L0.373047 11.1841L0.373046 8.81575L15.919 8.81575Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  </div>
                </a>
           
              </div>
            </div>
            <div
              className="w-full md:w-[1/2] flex justify-center mt-8 md:mt-0 order-1 md:order-2"
              data-aos="fade-up"
              data-aos-duration={2000}
              data-aos-delay={200}
            >
              <div className="abt-img mb-3 md:mb-0 ">
                <img src={abtimg} className="abt-img1 " alt="" />
              </div>
            </div>
          </div>
          <div className="section_about-bg">
            <div className="section_about-bg-gradient" />
          </div>
        </div>
      </section>
    </>
  );
};

export default Aboutsec;
