import React, {
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
  useMemo,
} from "react";
import tokenomicsvid from "../../Assets/images/tokenomics.mp4";
import tmtlogo from "../../Assets/images/techmontlogo.png";
import polygonlogo from "../../Assets/images/polygon.png";
import "../Tokenomics/Tokenomics.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const Tokenomics = () => {
  const videoRef = useRef(null);
  const scrollTriggerInstance = useRef(null);
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    // Setup ScrollTrigger for the video section
    scrollTriggerInstance.current = ScrollTrigger.create({
      trigger: videoRef.current, // trigger when the video section comes into view
      start: "top center", // start when the top of the video section hits the center of the viewport
      end: "bottom center", // end when the bottom of the video section hits the center of the viewport
      onEnter: () => handlePlayFromStart(), // when scrolled into view
      onEnterBack: () => handlePlayFromStart(), // when scrolled back into view
      markers: false, // you can set this to true for debugging
    });

    return () => {
      // Clean up the specific ScrollTrigger instance on component unmount
      if (scrollTriggerInstance.current) {
        scrollTriggerInstance.current.kill(); // Kill the instance
      }
    };
  }, []);

  // Function to restart the video from the beginning
  const handlePlayFromStart = () => {
    if (videoRef.current) {
      videoRef.current.currentTime = 0; // Reset the video to start at 0
      try {
        videoRef.current.play(); // Attempt to play the video
      } catch (error) {
        console.error("Video playback failed", error); // Handle playback errors
      }
    }
  };

  const handleHover = () => {
    setHovered(true);
  };

  const contractAddress = "0xf1bD5af0629467423Ccc471237742dff21ED3E7f";
  const shortenedAddress = `${contractAddress.slice(0, 5)}...${contractAddress.slice(-5)}`;

  return (
    <section
      id="tokenomicsSrl"
      className="tokenomics-section relative py-5 md:py-12  px-2 sm:px-4"
    >
      <div className="container">
        <div className="rm_main" data-aos="fade-up">
          <div className="h2div mx-auto w-full max-w-fit">
            <h3 className="text-center">Tokenomics</h3>
          </div>
          <h2 className="adv-heading text-center text-white mt-4">
            <span className="hero-communities">Techmont's</span> Tokenomics
          </h2>
        </div>
        <div className="w-layout-blockcontainer tkncontainer mt-10 mx-auto max-w-6xl">
          <div className="w-container flex flex-col md:flex-row items-center justify-between tokenomics-wrapper py-8 gap-y-8 ">
            <div
              className="col-12 md:w-1/2 tkntable"
              data-aos="fade-up"
              data-aos-duration="1600"
              data-aos-delay="200" ffffffffffffffffffffffff
            >
              <div className="token-metrics ">
                <h2> Token Metrics</h2>
                <ul>
                  <li>
                    <strong>Token Name:</strong> TechMont
                  </li>
                  <li className="mt-3">
                    <strong>Ticker Symbol:</strong>
                    <div className="flex">
                      <img src={tmtlogo} alt="TMT Logo" /> TMT
                    </div>
                  </li>

                  <li className="mt-3">
                    <strong>Chain:</strong>
                    <div className="flex">
                      <img src={polygonlogo} alt="polygon Logo" /> Polygon
                    </div>
                  </li>
                  <li className="mt-3">
                    <strong>Total supply:</strong> 9,999,999
                  </li>
                  <li className="mt-3">
                    <strong>Contract:</strong>
                    <div className="flex items-center" id="contract-address">
                      <a
                        href={`https://polygonscan.com/token/${contractAddress}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="underline"
                      >
                        {shortenedAddress}
                      </a>
                    </div>
                  </li>
                </ul>
                <div className="bnrbtn flex flex-wrap justify-center mx-auto mt-6 gap-4 sm:gap-7">
                  <a
                    href="https://polygonscan.com/token/0xf1bD5af0629467423Ccc471237742dff21ED3E7f"
                    target="_blank"
                    className="tech_btn flex items-center"
                  >
                    <i></i>
                    <i></i>
                    <span>View Contract</span>
                  </a>
                </div>
              </div>
            </div>
            <div
              className="col-12 md:w-1/2"
              data-aos="fade-up"
              data-aos-duration="1800"
              data-aos-delay="200">
              <div
                ref={videoRef}
                onMouseEnter={handleHover}
                onMouseLeave={() => setHovered(false)}
                className="tokenimg">
                <video
                  className="tknimg2"
                  ref={videoRef}
                  src={tokenomicsvid}
                  alt="Token Distribution"
                  width="100%"
                  height="100%"
                  muted
                  playsInline
                  autoPlay
                  loop
                  style={{ opacity: 1 }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Tokenomics;
