import React from "react";
import "./Advantages.css";
import features1 from "../../Assets/images/nft.webp";
import features2 from "../../Assets/images/features2.webp";
import features3 from "../../Assets/images/features4.webp";
import features4 from "../../Assets/images/features3.webp";
import features5 from "../../Assets/images/token.webp";
import features6 from "../../Assets/images/features1.webp";
import features7 from "../../Assets/images/swap.webp";
import features8 from "../../Assets/images/features2.webp";
import nftpass from "../../Assets/images/nftpass.mp4";

const Advantages = () => {
  return (
    <>
      <section
        className="advantages py-5 md:py-12 rm_section"
        id="advantagesSrl"
      >
        <div className="container mx-auto px-3">
          <div className="rm_main" data-aos="fade-up">
            <div className="h2div mx-auto w-full max-w-fit">
              <h3 className="text-center">Features</h3>
            </div>
            <h2 className="adv-heading text-center text-white mt-3">
              <span className="hero-communities">Techmont's</span> features
            </h2>
          </div>

          <div className="flex flex-wrap -mx-4 mt-10">
            <div className="w-full  xl:w-1/4 lg:w-1/3 md:w-1/2 px-3 mb-6 relative">
              <div className="adv-item flex items-center" data-aos="fade-up">
                <div className="adv-info">
                  <img
                    src={features1}
                    className=" w-20 h-auto ml-4 mt-3"
                    alt=""
                  />
                  <p className="description text-white px-4 py-4 leading-6  ">
                    NFT Staking Platform:<br></br>
                    Our state-of-the-art platform allows users to stake their
                    NFTs seamlessly on the Polygon Chain, earning rewards in a
                    secure and decentralized manner.
                  </p>
                </div>
              </div>
            </div>
            <div className="  w-full xl:w-1/4 lg:w-1/3 md:w-1/2 px-3 relative mb-6">
              <div className=" adv-item flex items-center" data-aos="fade-up">
                <div className="adv-info">
                  <img
                    src={features2}
                    className=" w-20 h-auto ml-4 mt-3"
                    alt=""
                  />
                  <p className="description text-white px-4 py-2 sm:py-4 leading-6  ">
                    Rapid Transactions:<br></br>
                    Leveraging Polygon's Layer 2 scaling solution, users benefit
                    from fast and cost-efficient transactions, eliminating
                    concerns over high gas fees and slow processing times.
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full xl:w-1/4 lg:w-1/3 md:w-1/2 relative px-3 mb-6">
              <div className="adv-item  flex items-center" data-aos="fade-up">
                <div className="adv-info">
                  <img
                    src={features3}
                    className=" w-20 h-auto ml-4 mt-3"
                    alt=""
                  />
                  <p className="description text-white px-4 py-2 sm:py-4  leading-6  ">
                    Enhanced Security:<br></br>
                    Built on the robust foundation of Polygon, our platform
                    ensures top-notch security, with smart contracts
                    safeguarding fair and transparent rewards distribution.
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full xl:w-1/4 lg:w-1/3 md:w-1/2 px-3 mb-6 relative">
              <div className="adv-item flex items-center" data-aos="fade-up">
                <div className="adv-info">
                  <img
                    src={features4}
                    className=" w-20 h-auto ml-4 mt-3"
                    alt=""
                  />
                  <p className="description text-white px-4 py-2 sm:py-4  leading-6  ">
                    Portfolio Diversification:<br></br>
                    Users can diversify their portfolios by earning rewards in
                    cryptocurrency or tokens, providing an additional passive
                    income stream to hedge against market volatility.
                  </p>
                </div>
              </div>
            </div>
            <div className="  w-full xl:w-1/4 lg:w-1/3 md:w-1/2 px-3 relative mb-6">
              <div className=" adv-item flex items-center" data-aos="fade-up">
                <div className="adv-info">
                  <img
                    src={features5}
                    className=" w-20 h-auto ml-4 mt-3"
                    alt=""
                  />
                  <p className="description text-white px-4 py-4 leading-6  ">
                    Techmont Token:<br></br>
                    Earn and utilize our native token within the Techmont
                    ecosystem for various benefits and services.
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full xl:w-1/4 lg:w-1/3 md:w-1/2 relative px-3 mb-6">
              <div className="adv-item  flex items-center" data-aos="fade-up">
                <div className="adv-info">
                  <img
                    src={features6}
                    className=" w-20 h-auto ml-4 mt-3"
                    alt=""
                  />
                  <p className="description text-white  px-4 py-4 leading-6  ">
                    Affiliate Program:<br></br>
                    Join our affiliate program to earn commissions by promoting
                    Techmont's platform and services.
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full xl:w-1/4 lg:w-1/3 md:w-1/2 relative px-3 mb-6">
              <div className="adv-item  flex items-center" data-aos="fade-up">
                <div className="adv-info">
                  <img
                    src={features7}
                    className=" w-20 h-auto ml-4 mt-3"
                    alt=""
                  />
                  <p className="description text-white  px-4 py-4 leading-6  ">
                    Techmont Swap:<br></br>
                    Seamlessly exchange cryptocurrencies using our user-friendly
                    swap feature.
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full xl:w-1/4 lg:w-1/3 md:w-1/2 relative px-3 mb-6">
              <div className="adv-item  flex items-center" data-aos="fade-up">
                <div className="adv-info">
                  <img
                    src={features8}
                    className=" w-20 h-auto ml-4 mt-3"
                    alt=""
                  />
                  <p className="description text-white  px-4 py-4 leading-6  ">
                    Initial Coin Offering (ITO):<br></br>
                    Participate in our ITO to invest in Techmont's future growth
                    and innovations.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="aboutusSrl" className="about-section pb-5   ">
        <div className="container mx-auto px-2 sm:px-4 relative">
          <div className="flex flex-col md:flex-row   items-center justify-between">
            <div
              className="w-full md:w-[1/2] order-2 "
              data-aos="fade-Up"
              data-aos-duration={1500}
              data-aos-delay={200}
            >
              <div className="h2div max-w-[90px] w-full mb-2 mx-auto md:mx-0  ">
                <h3 className="text-center">Nft</h3>
              </div>
              <h2 className="about-heading text-center md:text-left leading-none">
                <span className="hero-connecting">
                  {" "}
                  How <span className="hero-communities"> Techmont's</span> Nft
                  Staking Works{" "}
                </span>
              </h2>

              <div className="abtbx mt-4 p-2 sm:p-0 max-w-full">
                <a href="#" className="about-box p-0 sm:p-[20px]">
                  <p className=" text-justify  pb-1">
                    Users can easily stake their NFTs on the Techmont platform
                    by connecting their wallets to the Polygon network. Staked
                    NFTs will be locked for a predetermined period, during which
                    users will earn rewards in our native token. Rewards are
                    distributed automatically via smart contracts, ensuring a
                    fair and transparent process.
                  </p>

                  <div className="section_about-arrow-wrapper">
                    <div className="section_about-arrow invisible w-embed">
                      <svg
                        width={21}
                        height={20}
                        viewBox="0 0 21 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M15.919 8.81575L9.06818 2.46382L10.8741 0.789396L20.8081 9.99992L10.8741 19.2104L9.06818 17.536L15.919 11.1841L0.373047 11.1841L0.373046 8.81575L15.919 8.81575Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                    <div className="section_about-arrow w-embed">
                      <svg
                        width={21}
                        height={20}
                        viewBox="0 0 21 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M15.919 8.81575L9.06818 2.46382L10.8741 0.789396L20.8081 9.99992L10.8741 19.2104L9.06818 17.536L15.919 11.1841L0.373047 11.1841L0.373046 8.81575L15.919 8.81575Z"
                          fill="white"
                        />
                      </svg>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div
              className="w-full md:w-[1/2] flex justify-center md:justify-start  mt-8 md:mt-0 order-1"
              data-aos="fade-up"
              data-aos-duration={2000}
              data-aos-delay={200}
            >
              <div className="abt-img ">
                <video
                  src={nftpass}
                  className="abt-img1"
                  autoPlay
                  loop
                  muted
                  playsInline
                  controls={false}
                />
              </div>
            </div>
          </div>
          <div className="section_about-bg">
            <div className="section_about-bg-gradient" />
          </div>
        </div>
      </section>
    </>
  );
};

export default Advantages;
