import React, {
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
  useMemo,
} from "react";
import Aboutsec from "../../Components/Aboutsec/Aboutsec";
import Banner from "../../Components/Banner/Banner";
import Faqs from "../../Components/Faqs/Faqs";
import Advantages from "../../Components/Advantages/Advantages";
import Roadmap from "../../Components/Roadmap/Roadmap";
import toast, { Toaster } from "react-hot-toast";
import Tokenomics from "../../Components/Tokenomics/Tokenomics";

const Home = () => {
  return (
    <>
      <Banner />
      <Aboutsec />
      <Advantages />
      <Tokenomics />
      <Roadmap />
      <Faqs />
      <Toaster position="bottom-right" reverseOrder={false} />
    </>
  );
};

export default Home;
