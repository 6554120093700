import React from "react";
import "./Banner.css";
import bitcoinImg from "../../Assets/images/bitcoin.webp";
import ethereumImg from "../../Assets/images/ethereum.webp";
// import Countdown from "react-countdown";

// import Countdown from "react-countdown";
import { CopyToClipboard } from "react-copy-to-clipboard";
import toast, { Toaster } from "react-hot-toast";
import { Link } from "react-scroll";

const notify = () => toast.success("Copied Successfully!");
const Banner = () => {
  return (
    <>
      <section className="section hero overflow-hidden pb-5 ">
        <div className="container hero ">
          <div className="hero-aling  sm:mt-0">
            <div className="hero-div px-3 md:px-0">
              <div className="h2div w-full max-w-fit ">
                <h3 className="">TechMont</h3>
              </div>
              <h1
                className="hero-heading mt-3"
                data-aos="fade-up"
                data-aos-duration={1500}
                data-aos-delay={200}
              >
                <span className="hero-communities"> Techmont: </span>
                <span className="hero-connecting">
                  {" "}
                  Scaling New Heights in Digital Ownership
                </span>
              </h1>
              <p
                className="hero-desc text-justify md:text-center"
                data-aos="fade-up"
                data-aos-duration={2000}
                data-aos-delay={200}
              >
                Techmont, an innovative blockchain company at the forefront of
                decentralized applications, proudly presents its
                state-of-the-art NFT Staking Platform, seamlessly integrated
                with the Polygon Chain. Our platform enables users to optimize
                their NFT assets through a secure and decentralized staking
                process, offering compelling rewards.
              </p>

              <div className="bnrbtn flex buttonGroup  justify-center mx-auto mt-6 gap-3 ">
                <a href="https://affiliate.techmont.finance/" target="_blank" className="  tech_btn flex items-center">
                  <i></i>
                  <i></i>
                  <span>Affiliate</span>
                </a>
              </div>
              <div className="bnr-btc ">
                <img
                  alt=""
                  sizes="512px"
                  src={bitcoinImg}
                  width={512}
                  height={512}
                  decoding="async"
                  data-nimg={1}
                  className="eth-img emuzmgo6"
                  loading="lazy"
                  style={{ color: "transparent" }}
                />
                <div className="bnr-eth-bg " style={{ left: 30, bottom: 29 }} />
              </div>
              <div
                className="bnr-eth "
                style={{ transform: "translate3d(0px, -15.8547px, 0px)" }}
              >
                <img
                  alt=""
                  sizes="512px"
                  src={ethereumImg}
                  width={512}
                  height={512}
                  decoding="async"
                  data-nimg={1}
                  className="eth-img "
                  loading="lazy"
                  style={{ color: "transparent" }}
                />
                <div className="bnr-eth-bg " style={{ top: 69, left: 62 }} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="nftStaking py-12 overflow-visible relative   "
        id="howtechsrl"
      >
        <div className="container px-2 sm:px-4 z-50  ">
          <div className="ecosystem  flex flex-col justify-center max-w-screen-lg mx-auto">
            <div className="ecosystem-btn px-5 sm:px-10 py-12">
              <div className=" mb-2" data-aos="fade-up">
                <div className="h2div mx-auto w-full max-w-fit">
                  <h3 className="text-center">Ecosystem</h3>
                </div>
                <h2 className="adv-heading text-center text-white mt-3">
                  Explore <span className="hero-communities">Techmont's</span>{" "}
                  Ecosystem
                </h2>
              </div>

              <div className="descrecosystem  max-w-6xl text-center mx-auto">
                <p>Your Gateway to Innovation and Decentralization</p>
                <div className="bnrbtn flex flex-wrap justify-center mx-auto mt-6 gap-4 sm:gap-7">
                  {/* https://opensea.io/assets/matic/0x61c0112eb71ad55ae3a0809fdac2307d0b70a2fe/1 */}
                  <a href="https://opensea.io/assets/matic/0x64fbe21c49c12899d3325805bc3dc9fcc0287b57/2" target="_blank" className="tech_btn flex items-center">
                    <i></i>
                    <i></i>
                    <span>Buy Nft</span>
                  </a>
                  <a
                    href="https://swap.techmont.finance/" target="_blank"
                    className="tech_btn flex items-center"
                  >
                    <i></i>
                    <i></i>
                    <span>Swap</span>
                  </a>

                  <a
                    href="https://sale.techmont.finance/"
                    target="_blank" className="tech_btn flex items-center"
                  >
                    <i></i>
                    <i></i>
                    <span>ITO Sale</span>
                  </a>

                  <a
                    href="https://nft.techmont.finance/" target="_blank"
                    className="tech_btn flex items-center"
                  >
                    <i></i>
                    <i></i>
                    <span>NFT Staking</span>
                  </a>

                  <a
                    href="https://stake.techmont.finance/" target="_blank"
                    className="tech_btn flex items-center"
                  >
                    <i></i>
                    <i></i>
                    <span>Token Staking</span>
                  </a>

                  <a
                    href="https://game.techmont.finance/" target="_blank"
                    className="tech_btn flex items-center"
                  >
                    <i></i>
                    <i></i>
                    <span>Web3 games</span>
                  </a>

                  <a
                    href="https://e-commerce.techmont.finance/" target="_blank"
                    className="tech_btn flex items-center"
                  >
                    <i></i>
                    <i></i>
                    <span>E-commerce</span>
                  </a>

                  <a
                    href="https://pay.techmont.finance/" target="_blank"
                    className="tech_btn flex items-center"
                  >
                    <i></i>
                    <i></i>
                    <span>Techmont pay</span>
                  </a>

                  <a
                    href="https://blockchain.techmont.finance/" target="_blank"
                    className="tech_btn flex items-center"
                  >
                    <i></i>
                    <i></i>
                    <span> Blockchain</span>
                  </a>

                  <a
                    href="https://forex.techmont.finance/" target="_blank"
                    className="tech_btn flex items-center"
                  >
                    <i></i>
                    <i></i>
                    <span>Forex Trading platform</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Toaster position="bottom-right" reverseOrder={false} />
    </>
  );
};

export default Banner;
